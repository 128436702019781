import React from "react";
import { motion } from "framer-motion";
import "./workHome.css";
import WorkCardLeft from "./WorkCardLeft";
import WorkCardRight from "./WorkCardRight";
import BusCard from "./assets/bus_cards.svg";
// import TrainsTraveller from "./assets/trains_traveller.svg";
import TrainsSRP from "./assets/trainsSRP.svg";
// import HotelsPWA from "./assets/hotelsPWA.svg";
// import Brin from "./assets/brin.svg";
import Playment from "./assets/playment.svg";
import Flipkart from "./assets/flipkartPWA.svg";
import BusSeat from "./assets/bus_seat_selection.svg";
import Metro from "./assets/metro.svg";
import { NavLink } from "react-router-dom";
import * as sectionRefHolder from "../../sectionRefHolders";
import WorkCardLeftWithLink from "./WorkCardLeftWithLink";
import WorkCardRightWithLink from "./WorkCardRightWithLink";

function Work() {
  return (
    <motion.div
      className="workCards"
      ref={(elRef) => {
        sectionRefHolder.setRef(sectionRefHolder.SECTIONS.WORK, elRef);
      }}
    >
      <motion.div className="titleGradient">
        <motion.div className="gradient1"></motion.div>
        <motion.div className="workTitle">Work</motion.div>
      </motion.div>
      <motion.div className="allCards">
        <WorkCardLeft
          width="120px"
          backgroundColor="#EB2026"
          image={TrainsSRP}
          title="Trains booking experience redesign"
          company="MakeMyTrip"
          navLink="/work/trains_redesign"
          description="The train funnel was redesigned to make it easier to use. It emphasized on helping users choose trains quickly and efficiently."
          read="Read more."
        />
        <WorkCardRightWithLink
          width="120px"
          backgroundColor="#EB2026"
          image={BusSeat}
          title="Bus seat selection experience redesign"
          company="MakeMyTrip"
          navLink="https://medium.com/designers-of-mmt/taking-the-seat-selection-experience-from-regular-to-a-dapper-fit-for-makemytrip-buses-b121d5ec68e4"
          description="Here is a case study on how the team uncovered research insights and made the seat selection experience better, thus improving the conversions."
          read="Read more."
        />
        {/* <WorkCardRight
          width="120px"
          backgroundColor="#EB2026"
          image={BusCard}
          title="Bus Funnel Redesign"
          company="MakeMyTrip"
          description="After a round of fresh user research & testing, and considering the app usage data, we redesigned the bus funnel. Case study coming soon."
          read=""
        /> */}

        <WorkCardLeft
          width="120px"
          backgroundColor="#EB2026"
          image={Metro}
          title="Introducing MakeMyTrip Metro"
          company="MakeMyTrip"
          navLink=""
          description="MMT launched Metro ticket booking services at Hyderabad recently. We designed one of the quickest funnel to book Metro tickets. Case study coming soon."
          read=""
        />
        <WorkCardRightWithLink
          width="120px"
          backgroundColor="#4E62F7"
          image={Playment}
          title="Playment app redesign"
          company="Playment"
          navLink="https://my-portfolio-site-62bf4.web.app/projects/playment"
          description="Playment is a crowdsourcing platform to perform large scale data operations. The app needed a redesign to make it more user-friendly and easy to use. "
          read="Read more."
        />

        <WorkCardLeftWithLink
          width="120px"
          backgroundColor="#E5B203"
          image={Flipkart}
          title="Flipkart PWA - increase sign-up"
          navLink="https://my-portfolio-site-62bf4.web.app/projects/flipkart"
          company="Flipkart"
          description="After the release of PWA, we did not see much sign-ups. This project was taken up to increase the number of sign-up of people visiting the funnel."
          read="Read more."
        />
      </motion.div>

      {/* More projects link to be activated when all the projects are completed and uploaded */}

      {/* <motion.div>
        <NavLink to="/selectedWork" style={{ textDecoration: "none" }}>
          <p className="SeeMore">View More Projects</p>
        </NavLink>
      </motion.div> */}
    </motion.div>
  );
}

export default Work;
