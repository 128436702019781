import React from "react";
import { motion } from "framer-motion";
import "./beforeAfter.css";

import OldTraveler from "./asset/oldTravelerPage2.png";
import NewTravelPage from "./asset/newTravelerPage.png";
import ListingNewPage from "./asset/listingNewPage.png";
import ListingOldPage from "./asset/listingOldPage.png";

function BeforeAfter() {
  return (
    <motion.div className="backroundWrapper">
      <p className="headingTextBeforeAfter">
        Before and <br />
        after designs
      </p>
      <div className="imageSection">
        <div className="imageWrapper">
          <p className="subHeading">Old Listing Page</p>
          <img src={ListingOldPage} alt="old listing page" className="images" />
        </div>
        <div className="imageWrapper">
          <p className="subHeading">New Listing Page</p>
          <img src={ListingNewPage} alt="old listing page" className="images" />
        </div>
      </div>
      <div className="imageSection">
        <div className="imageWrapper">
          <p className="subHeading">Old Traveler Page</p>
          <img src={OldTraveler} alt="old travel page" className="images" />
        </div>
        <div className="imageWrapper">
          <p className="subHeading">New Traveler Page</p>
          <img src={NewTravelPage} alt="new travel page" className="images" />
        </div>
      </div>
    </motion.div>
  );
}

export default BeforeAfter;
