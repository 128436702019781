import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import AppOnboarding from "../../AllExperiments/assets/onboarding.png";

function OnBoarding() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>App Onboarding</h1>
        <p className="description">
          This was a side time project to hone my illustration skills. This
          illustration is done as an onboarding screen of a real estate app.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={AppOnboarding} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default OnBoarding;
