import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import Home from "../../AllExperiments/assets/home.png";
import Play from "../../AllExperiments/assets/play.png";
import Shake from "../../AllExperiments/assets/shake.png";
import Wash from "../../AllExperiments/assets/wash.png";
import Mask from "../../AllExperiments/assets/mask.jpg";
import Sixft from "../../AllExperiments/assets/6ft.png";

function Blender() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Blender Experiments</h1>
        <p className="description">
          The images are from a series dedicated to Covid 19 precaution
          awareness. I wanted to pickup 3d modelling again as a tool for 3d
          content creation and Blender was the answer to it. Blender is open
          source and free which made it easier for me to pick up the skill at my
          own pace and not worry for the subscription fee.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={Home} alt="" className="projectImages" />
        <img src={Shake} alt="" className="projectImages" />
        <img src={Play} alt="" className="projectImages" />
        <img src={Wash} alt="" className="projectImages" />
        <img src={Mask} alt="" className="projectImages" />
        <img src={Sixft} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default Blender;
