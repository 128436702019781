import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import ShantiStupa from "../../AllExperiments/assets/website_illustrations.png";

function Icon() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Icon Illustrations</h1>
        <p className="description">
          These are part of the illustrations done for the redesign of the
          website of Playment. These icons were used in the career page to
          describe what type of candidates the company was looking for.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={ShantiStupa} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default Icon;
