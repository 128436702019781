import React from "react";
import { motion } from "framer-motion";
import "./workCardRight.css";

function WorkCardRightWithLink(props) {
  return (
    <motion.div className="cardRight">
      <motion.div className="cardTextRight">
        <motion.div>
          <p className="cardTitleRight">{props.title}</p>
        </motion.div>
        <motion.div>
          <p
            className="cardCompanyRight"
            style={{
              backgroundColor: `${props.backgroundColor}`,
              width: `${props.width}`,
            }}
          >
            {props.company}
          </p>
        </motion.div>
        <motion.div>
          <p className="cardDescriptionRight">
            {props.description}{" "}
            <a href={props.navLink} className="cardRightNavLink">
              <span className="readMoreRight ">{props.read}</span>
            </a>
          </p>
        </motion.div>
      </motion.div>
      <motion.div className="cardImageRight">
        <img
          src={props.image}
          alt="project_image"
          className="cardImageContentRight"
        />
      </motion.div>
    </motion.div>
  );
}

export default WorkCardRightWithLink;
