export const SECTIONS = {
  EXPERIMENTS: "EXPERIMENTS",
  WORK: "WORK"
};

const _refMap = {};

export const setRef = (name, ref) => {
  _refMap[name] = ref;
};

export const getRef = name => {
  return _refMap[name];
};
