import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import P5js from "../../AllExperiments/assets/circlePacking.png";

function P5() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>P5.js + React.js explorations</h1>
        <p className="description">
          Exploring P5.js and React.js while following Daniel Shiffman's
          tutorials for learning Processing. There are three experiments that I
          tried from the examples Shiffman has showed in this tutorial videos.
          The image shown below is packing a geometric shape into a black and
          white image. The geometric shapes would be randomly generated of
          different sizes and be filled in the black space and the white space
          would be ignored.
        </p>
        <p className="description">
          The other two examples are - tracing a text and putting circles on the
          traced text. If you bring you computers mouse near the circles, then
          they would move away from it and then would come back to their
          respective positions. The next example is emulating the flocking
          behavior of birds. When you click on the screen a new object is
          created and then It would start moving in a random direction. If it
          comes closer to another object, it would change direction and either
          it would move away or align with the direction of the object it was
          supposed to hit.
        </p>
        <p>
          Please check out the code
          <a
            href="https://github.com/baisampayans/react-p5"
            style={{
              textDecoration: "none",
              color: "#f5a623",
              fontWeight: "900"
            }}
          >
            here
          </a>
          .
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={P5js} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default P5;
