import React from "react";
import Braun from "./braun_tp1.png";
import Myra from "./myra.gif";
import Travel from "./travel.gif";
import Mumbai from "./good_bye_2016.png";
import { motion } from "framer-motion";
import "./intro.css";
import { NavLink } from "react-router-dom";
import Navbar from "../navbar/Navbar";

function Intro() {
  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
    hidden: {
      opacity: 0,
      y: 20,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <motion.div className="intro">
      <motion.div className="introWrapper">
        <motion.svg width="691" height="611" className="patternImage">
          <defs>
            <linearGradient x1="-2.85%" y1="-3.122%" x2="100%" y2="100%" id="a">
              <stop stopColor="#FF0082" offset="0%" />
              <stop stopColor="#C11000" offset="50.412%" />
              <stop stopColor="#3800FF" offset="100%" />
            </linearGradient>
          </defs>
          <g
            fill="none"
            stroke="url(#a)"
            strokeWidth="1"
            fillRule="evenodd"
            fillOpacity="0"
            opacity="0.5"
          >
            <motion.path
              d="M1.5 382.5h381V1.5H1.5v381zm73.8-153.6h233.4v79.8H75.3v-79.8zM308.7 75.3v79.8H75.3V75.3h233.4z"
              initial={{ pathLength: 0, pathOffset: 1 }}
              animate={{ pathLength: 1, pathOffset: 0 }}
              transition={{ duration: 7, flip: "Infinity", ease: "easeInOut" }}
            />
            <motion.path
              d="M122.5 494.5h381v-381h-381v381zm73.8-153.6h233.4v79.8H196.3v-79.8zm233.4-153.6v79.8H196.3v-79.8h233.4z"
              initial={{ pathLength: 0, pathOffset: 1 }}
              animate={{ pathLength: 1, pathOffset: 0 }}
              transition={{ duration: 8, flip: "Infinity", ease: "easeInOut" }}
            />
            <motion.path
              d="M308.5 609.5h381v-381h-381v381zm73.8-153.6h233.4v79.8H382.3v-79.8zm233.4-153.6v79.8H382.3v-79.8h233.4z"
              initial={{ pathLength: 0, pathOffset: 1 }}
              animate={{ pathLength: 1, pathOffset: 0 }}
              transition={{ duration: 9, flip: "Infinity", ease: "easeInOut" }}
            />
          </g>
        </motion.svg>

        <motion.div className="leftRightWrapper">
          <motion.div
            className="left"
            initial="hidden"
            animate="visible"
            variants={variants}
          >
            <p className="hello">Hey 👋</p>
            {/* &lt;Hello world /&gt; */}
            <h1 className="craft">I am</h1>
            <h1 className="experience">Baisampayan.</h1>
            <p className="description">
              I am a Senior Design Manager at Microsoft. Previously, I was a UX Design Manager at MakeMyTrip, leading the Ground Transport team (Bus,
              Trains, Metro, Cabs, & FleetSimply) and the Bus charter at Goibibo. I had also worked at Playment and Flipkart. 
            </p>
            <p className="description">
              I love experimenting with design and code and see where they meet.
              Currently, I'm learning Blender and SwiftUI.
            </p>
            <p className="description">
              Bitten by the triathlon bug, I'm training for Ironman 70.3 while
              juggling between training, family time and my usual experiments.
              Oh, I am also a big foodie.
            </p>

            <p className="description">
              Do{" "}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeiDcRonb38e6a78XYhYcVbZIjUcqfFbNet9T3LDnPKy19VnQ/viewform?vc=0&c=0&w=1"
                className="linkReachOut"
              >
                reach out
              </a>{" "}
              to me if you find my work interesting and want to collaborate or
              to find out the best eateries in town 😊.
            </p>
          </motion.div>

          <motion.div
            className="right"
            initial="hidden"
            animate="visible"
            variants={variants}
          >
            <motion.div className="leftImagesWrapper">
              <motion.div className="leftTop">
                <NavLink to="/experiments/travel" className="introNavLink2">
                  <motion.img src={Travel} alt="travel" className="bigImage" />
                </NavLink>
              </motion.div>

              <motion.div className="leftBottom">
                <NavLink to="experiments/tp1" className="introNavLink">
                  <motion.img
                    src={Braun}
                    alt="braun radio"
                    className="smallImage"
                  />
                </NavLink>
              </motion.div>
            </motion.div>
            <motion.div className="rightImagesWrapper">
              <motion.div className="rightTop">
                <NavLink to="/experiments/powaiLake" className="introNavLink">
                  <motion.img
                    src={Mumbai}
                    alt="powai lake"
                    className="smallImage"
                  />
                </NavLink>
              </motion.div>
              <motion.div className="rightBottom">
                <NavLink to="/experiments/myra" className="introNavLink">
                  <motion.img
                    src={Myra}
                    alt="voice assistant"
                    className="myra"
                  />
                </NavLink>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default Intro;
