import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import About from "./components/AboutMe/AboutMe";
import Navbar from "./components/navbar/Navbar";
import SelectedWork from "./components/SelectedWork/SelectedWork";
import Experiments from "./components/Experiments/Experiments";
import Footer from "./components/Footer/Footer";
import MumbaiWeather from "./components/AllExperiments/ExperimentPages/Mumbai Weather";
import Tp1 from "./components/AllExperiments/ExperimentPages/braunTp1";
import Calendar from "./components/AllExperiments/ExperimentPages/Calendar";
import PowaiLake from "./components/AllExperiments/ExperimentPages/powaiLake";
import Hld from "./components/AllExperiments/ExperimentPages/braunHld";
import Kickstarter from "./components/AllExperiments/ExperimentPages/kickStarter";
import Message from "./components/AllExperiments/ExperimentPages/message";
import Shanti from "./components/AllExperiments/ExperimentPages/shanti";
import Travel from "./components/AllExperiments/ExperimentPages/Travel";
import Icon from "./components/AllExperiments/ExperimentPages/icon";
import Welder from "./components/AllExperiments/ExperimentPages/welderNation";
import PlaymentSite from "./components/AllExperiments/ExperimentPages/playmentWebsite";
import OnBoarding from "./components/AllExperiments/ExperimentPages/onBoarding";
import P5 from "./components/AllExperiments/ExperimentPages/P5Home";
import Myra from "./components/AllExperiments/ExperimentPages/myra";
import ReactGa from "react-ga";
import TrainRedesign from "./components/SelectedWork/Trains Redesign/trainsRedesign";
import BeforeAfter from "./components/SelectedWork/Trains Redesign/BeforeAfter";
import Blender from "./components/AllExperiments/ExperimentPages/blender";

// class Home extends Component {
//   render() {
function Home() {
  useEffect(() => {
    ReactGa.initialize("UA-158149689-1");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <BrowserRouter>
      <Navbar />
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/about" component={About} />
        <Route exact path="/work/trains_redesign" component={TrainRedesign} />
        <Route
          exact
          path="/work/trains_redesign/before_after"
          component={BeforeAfter}
        />

        <Route exact path="/selectedWork" component={SelectedWork} />
        <Route exact path="/experiments" component={Experiments} />
        <Route
          exact
          path="/experiments/mumbaiWeather"
          component={MumbaiWeather}
        />
        <Route exact path="/experiments/blender" component={Blender} />
        <Route exact path="/experiments/tp1" component={Tp1} />
        <Route exact path="/experiments/calendar" component={Calendar} />
        <Route exact path="/experiments/myra" component={Myra} />
        <Route exact path="/experiments/powaiLake" component={PowaiLake} />
        <Route exact path="/experiments/hld" component={Hld} />
        <Route exact path="/experiments/kickstarter" component={Kickstarter} />
        <Route exact path="/experiments/message" component={Message} />
        <Route exact path="/experiments/shanti" component={Shanti} />
        <Route exact path="/experiments/travel" component={Travel} />
        <Route exact path="/experiments/icons" component={Icon} />
        <Route exact path="/experiments/welder" component={Welder} />
        <Route
          exact
          path="/experiments/playmentWebsite"
          component={PlaymentSite}
        />
        <Route exact path="/experiments/onboarding" component={OnBoarding} />
        <Route exact path="/experiments/p5" component={P5} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}
// }

export default Home;
