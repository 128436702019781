import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import MyraImage from "../../Intro/myra.gif";

function Myra() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Myra - Travel Assistant</h1>
        <p className="description">
          This was one of the attempts at creating a voice assisted chat bot for
          MakeMyTrip. This was created to quickly test out and check out
          hypothesis. This idea was not taken forward for further development.
        </p>
        <p>
          Check out the Framer Prototype{" "}
          <a
            href="https://framer.cloud/nKAeF"
            style={{
              textDecoration: "none",
              fontWeight: "900",
              color: "#f5a623"
            }}
          >
            here
          </a>
        </p>
      </motion.div>
      <motion.div
        className="imageContainer"
        style={{ backgroundColor: "rgba(255,255,255,0.1)" }}
      >
        <img src={MyraImage} alt="" className="projectImageMyra" />
      </motion.div>
    </motion.div>
  );
}

export default Myra;
