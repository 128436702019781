import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import Kick from "../../AllExperiments/assets/kickstarter.gif";

function Kickstarter() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Kickstarter Logo Animation</h1>
        <p className="description">
          The original animation of the logo was done by a team called{" "}
          <a
            href="https://dribbble.com/shots/2075475-KickMaterial-Kickstarter-Material-app-icon"
            style={{
              color: "#f5a623",
              textDecoration: "none",
              fontWeight: "900"
            }}
          >
            Outline
          </a>
          . I thought if I could replicate the same in Framer Studio using code,
          it would be a fun way to hone my Framer skills. I have changed a few
          things in the animation. The original one by Outline is awesome.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={Kick} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default Kickstarter;
