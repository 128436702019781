import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import CalendarImage from "../../AllExperiments/assets/calendar.gif";

function Calendar() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Calendar</h1>
        <p className="description">
          Alternate interaction for calendar feature of MakeMyTrip App. The
          app's calendar fearture had few functional issues. We explored the
          interaction and this was one of the concepts we tested out. This was
          created in Framer.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={CalendarImage} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default Calendar;
