import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import Powai from "../../AllExperiments/assets/good_bye_2016.png";

function PowaiLake() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Powai Lake Sunset</h1>
        <p className="description">
          This is an illustration of the boat house in Powai Lake. The boat
          house is inside IIT Bombay, which has been one of my alma mater. The
          illustration is about contemplating about the events that occured in
          2016 and sitting by the boat house. The illustration is inspired by
          one of the photos that Google uses in cards to show travel related
          information when travelling to Mumbai.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={Powai} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default PowaiLake;
