import React from "react";
import Logo from "./logo.svg";
import "./navbar.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import * as sectionRefHodler from "../../sectionRefHolders";

function Navbar(props) {
  return (
    <motion.div className="navbar">
      <motion.div className="navWrapper">
        <motion.div className="logo">
          <NavLink to="/" className="navLinkLogo">
            <motion.img src={Logo} alt="logo" />

            {/* <motion.div className="logoText">
              <p style={{ marginBottom: 0, marginTop: 0 }}>Baisampayan</p>
              <p style={{ marginTop: 0, marginBottom: 0 }}>Saha</p>
            </motion.div> */}
          </NavLink>
        </motion.div>
        <motion.div className="navLinks">
          <motion.div className="linksWrapper">
            <motion.div
              className="link1"
              onClick={() => {
                const expElRef = sectionRefHodler.getRef(
                  sectionRefHodler.SECTIONS.EXPERIMENTS
                );
                if (expElRef) {
                  expElRef.scrollIntoView();
                }
                console.log("workElRef", expElRef);
              }}
            >
              <NavLink to="" className="navLinkItems">
                <p
                  style={{
                    marginBottom: 0,
                    textTransform: "uppercase",
                    fontSize: "12px"
                  }}
                >
                  Experiments
                </p>
                <p style={{ marginTop: 4 }}>
                  <span className="reduceOpacity">Few</span> side projects
                </p>
              </NavLink>
            </motion.div>
            <motion.div
              className="link2"
              onClick={() => {
                const workElRef = sectionRefHodler.getRef(
                  sectionRefHodler.SECTIONS.WORK
                );
                if (workElRef) {
                  workElRef.scrollIntoView();
                }
                console.log("workElRef", workElRef);
              }}
            >
              <NavLink to="/" className="navLinkItems">
                <p
                  style={{
                    marginBottom: 0,
                    textTransform: "uppercase",
                    fontSize: "12px"
                  }}
                >
                  Selected Works
                </p>
                <p style={{ marginTop: 4, marginBottom: 0, opacity: 0.4 }}>
                  Projects I would
                </p>
                <p style={{ marginTop: 0 }}>
                  like <span className="reduceOpacity">to </span> showcase
                </p>
              </NavLink>
            </motion.div>
            <motion.div className="link3">
              <NavLink to="/about" className="navLinkItems">
                <p
                  style={{
                    marginBottom: 0,
                    textTransform: "uppercase",
                    fontSize: "12px"
                  }}
                  className="aboutMe"
                >
                  About me
                </p>
                <p
                  style={{
                    marginTop: 4,
                    marginBottom: 0,
                    color: "#fff",
                    textDecoration: ""
                  }}
                  className="aboutMeText"
                >
                  <span className="reduceOpacity">My</span> background,
                </p>
                <p
                  style={{ marginTop: 0, color: "#fff", textDecoration: "" }}
                  className="aboutMeText"
                >
                  skills <span className="reduceOpacity">&</span> work
                </p>
              </NavLink>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default Navbar;
