import React from "react";
import { motion } from "framer-motion";
import "./workCardLeft.css";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

function WorkCardLeft(props) {
  return (
    <motion.div className="cardLeft">
      <motion.div className="cardImageLeft">
        <img
          src={props.image}
          alt="project_image"
          className="cardImageContentLeft"
        />
      </motion.div>
      <motion.div className="cardTextLeft">
        <motion.div>
          <p className="cardTitleLeft">{props.title}</p>
        </motion.div>
        <motion.div>
          <p
            className="cardCompanyLeft"
            style={{
              backgroundColor: `${props.backgroundColor}`,
              width: `${props.width}`
            }}
          >
            {props.company}
          </p>
        </motion.div>
        <motion.div>
          <p className="cardDescriptionLeft">
            {props.description}{" "}
            <NavLink to={props.navLink} className="cardLeftNavLink">
              <span className="readMoreLeft">{props.read}</span>
            </NavLink>
          </p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default WorkCardLeft;
