import React from "react";
import "./App.css";

import Intro from "./components/Intro/intro";
import Work from "./components/Work/WorkHome";
import AllExperiments from "./components/AllExperiments/AllExperiments";

function App() {
  return (
    <div className="App">
      <Intro />
      <Work />
      <AllExperiments />
    </div>
  );
}

export default App;
