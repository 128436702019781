import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import WelderNation from "../../AllExperiments/assets/welder_nation.png";

function Welder() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Welder Nation Illustration</h1>
        <p className="description">
          This is a laptop sticker and a freelance project done for Welder
          Nation. I got the oppurtinity to do some work for Welder Nation. This
          is one of the not selected work.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={WelderNation} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default Welder;
