import React from "react";
import { NavLink } from "react-router-dom";
import "./trainsRedesign.css";

import TrainImage from "./asset/intoImageTrains.png";
import ListingPageOld from "./asset/oldListingPage.png";
import ListingCardOld from "./asset/listingCards.png";
import Flow from "./asset/flow.png";
import Comp from "./asset/compAna.png";
import OldCard from "./asset/oldListingCardDesign-2.png";
import ListingVariations from "./asset/listingVariations.png";
import DateChanger from "./asset/dateChanger.png";
import Filters from "./asset/filters-2.png";
import ListingA from "./asset/listing page card type A with tatkal.png";
import ListingB from "./asset/listing page card type B with tatkal.png";
import OldTravel from "./asset/oldTravelerPage.png";
import newTravelInfo from "./asset/newTrainInfo.png";
import SelectTravel from "./asset/selectTravel.png";
import AdditionalInfo from "./asset/additionalPref.png";
import Discount from "./asset/discount.png";
import Agree from "./asset/agree.png";
import NewTravelerPage from "./asset/newTravelerPage.png";
import newListingCard from "./asset/newListingCard.png";

function TrainRedesign() {
  // const [toggle, setToggle] = useState(false);
  // console.log(toggle);

  //code for stoping body scoll when modal is active.
  // useEffect(() => {
  //   if (toggle) {
  //     document.body.style.overflow = "hidden";
  //     return () => (document.body.style.overflow = "unset");
  //   }
  // }, [toggle]);

  return (
    <>
      <div className="trainWrapper">
        <div className="trainIntro">
          <p className="headingText">
            Train Funnel <br />
            Redesign
          </p>
          <p className="bodyTextTrain">
            The train funnel was redesigned to make it easier to use, find
            trains quickly & efficiently. The redesign led to a significant jump
            in the page to page conversion percentage. <br /> <br />
            Read how we redesigned it below or{" "}
            <span>
              <NavLink
                to="/work/trains_redesign/before_after"
                className="tapToSkip"
              >
                tap here
              </NavLink>
            </span>{" "}
            to check out before and after designs.
          </p>

          <img src={TrainImage} alt="Train Intro" className="introImageTrain" />
        </div>
      </div>
      <div className="sectionWrapperFullWidth">
        <p className="headingText">
          Context & <br />
          Key Insights
        </p>
        <div className="sectionWrapperFlex">
          <div className="sectionWrapper1">
            <p className="bodyTextTrainLeft">
              - The train listing cards{" "}
              <span className="spanBold">took too much vertical space.</span> In
              a smaller phone, the user would be able to view 1.5 cards
              effectively.
              <br />
              <br /> - Our competitors were{" "}
              <span className="spanBold">showing more cards</span> in the same
              space.
              <br />
              <br /> - The filter section was{" "}
              <span className="spanBold">taking too much space</span> and could
              be optimised more.
              <br />
              <br /> - Many users were{" "}
              <span className="spanBold">
                booking in the tatkal window
              </span>{" "}
              (Tatkal is a scheme for booking train tickets at a very short
              notice and Tatkal tickets for a train are only available one day
              before the journey.) and it was
              <span className="spanBold"> hidden one step inside</span> another
              click/ tap.
              <br />
              <br /> - Most users would{" "}
              <span className="spanBold">
                go back to the landing page to change the date.
              </span>
              <br />
              <br /> - The traveler details section was{" "}
              <span className="spanBold">too long</span> and could be made
              shorter.
            </p>
          </div>
          <div className="sectionWrapper2">
            <img
              src={ListingPageOld}
              alt="Lisitng card old"
              className="imageTrainCards"
            />
          </div>
        </div>
      </div>

      <div className="sectionWrapperCenter">
        <p className="headingText">The User Journey</p>
        <p className="bodyTextTrain">
          Here is a typical user journey of a first time user and for a
          returning user. IRCTC password is needed only after the payment of
          train tickets. Due to IRCTC restriction on 3rd party vendors, the
          orange dotted line section is the portion that we have control and we
          can improve the experience of the user.
        </p>
        <img src={Flow} alt="Flow" className="imageTrainCardsFlow" />
      </div>

      <div className="sectionWrapperFullWidth">
        <p className="headingText">
          Areas of
          <br />
          Improvement
        </p>
        <div className="sectionWrapperFlex">
          <div className="sectionWrapper1">
            <p className="subHeader">Listing Page</p>
            <br />
            <p className="bodyTextTrainLeft">
              We identified 2 areas of improvement in the listing page -
              <br />
              <br />- Filter section <br />- Listing cards
              <br />
              <br />
              The listing cards can be further broken down into 3 sections -
              <br />
              <br />- Train Details
              <br />- Coach type & information
              <br />- Nearby dates & tatkal quota
            </p>
            <br />
            <br />

            <p className="subHeader">Traveler details page</p>
            <br />
            <p className="bodyTextTrainLeft">
              For Trains Traveler Details page, we found out that each
              individual section can be optimised and the total vertical scroll
              can be reduced. This would enable a user to fill details quicker
              at a less cognitive load.
            </p>
          </div>
          <div className="sectionWrapper2">
            <img
              src={ListingCardOld}
              alt="Lisitng card old"
              className="imageTrainCards"
            />
          </div>
        </div>
      </div>

      <div className="sectionWrapperCenter">
        <p className="headingText">Benchmarking</p>
        <p className="extraMargin bodyTextTrain">
          We checked out what other e-commerce platforms were doing to show
          train results. We did a heuristic evaluation of e-commerce platforms
          that sell train/ metro tickets in India and also in other countries.
          To name a few, we checked IRCTC, GoIbibo, PayTM, ixigo, Google trains,
          Rail Europe (formerly known as LOCO), DB navigator, trainline, etc. We
          now knew areas of improvement in our funnel.
        </p>
        <img src={Comp} alt="Flow" className="imageTrains " />
      </div>

      <div className="lightBg">
        <div className="sectionWrapperCenter ">
          <p className="headingText">Ideation</p>
          <p className="extraMargin bodyTextTrain">
            We started off with the Listing page and then moved to the Traveler
            details page. We came up with a couple of concepts and then selected
            two best concepts and went ahead with the user testing sessions to
            validate the concepts.
          </p>
          <img src={OldCard} alt="Flow" className="imageTrains " />
          <p className="bodyTextTrainLeftBottomSections">
            The listing page had 2 components - the filter and sorter section
            and the listing cards itself. Few problem statement we had -
            <br />
            <br />
            1. <span className="spanBold">Reduce the card size</span> so that
            more cards can be viewed in the first-fold of mobile screen enabling
            users to take quick decisions.
            <br />
            <br />
            2. The{" "}
            <span className="spanBold">
              focus was on the time of departure
            </span>{" "}
            of the train and not on the train name. We wanted to bring focus to
            the train name for quick scanning of the desired train name as a
            significant number of our users were repeat users.
            <br />
            <br />
            3. People usually{" "}
            <span className="spanBold">read from left to right</span> and{" "}
            <span className="spanBold">not from top to bottom</span> unless the
            script dictates the users to read from top to bottom. We wanted to
            make scanning of time easier and so make the reading direction of
            time from left to right.
            <br />
            <br />
            4.{" "}
            <span className="spanBold">
              Surface tatkal quota tickets on top
            </span>{" "}
            of the card as many users were booking in the tatkal window.
          </p>
          <p className="highlight">
            After a few rounds of iteration, we came up with these designs which
            fulfilled almost all the problem statements stated above.
          </p>
          <img
            src={ListingVariations}
            alt="Flow"
            className="imageTrainCardsFlow "
          />
          <p className="bodyTextTrain">
            If you see, all the cards had consistent train information at the
            top, what is different is the layout of the information of the seats
            in different coaches. There were two approaches behind it, one to
            keep seat information to bare minimum and another to keep more
            information. Availability was indicated by red/ orange or green
            color. We wanted to see if user can book a ticket with as little
            information as possible.
            <br />
            <br />
            We quickly did a floor test and selected participants who did not
            have any idea about the project and saw that the last two variations
            did a good job than the rest of the variations.{" "}
            <span className="spanBold">
              Users surprisingly did not have any problem with more information
              on the screen which we mostly consider to be cases of cognitive
              overload.
            </span>{" "}
            So now we wanted to test them out before finalising one.
            <br />
            <br />
            If you observe, we have removed the “Nearby dates” feature from the
            card as we have included a date changer feature taken up from our
            Flights line of business. Users can now easily change dates to see
            available trains from the top itself.
          </p>
          <img src={DateChanger} alt="Flow" className="imageTrainCardsFlow " />
          <p className="bodyTextTrain">
            For filter and sorters, we wanted to understand the behaviour of the
            user first. If you check the listing page screen shown in the
            “Context and Key Insights” section, you will see that the filter and
            sorter section is at the top. Whereas, filter and sorter in flights
            and hotels were at the bottom. In our Bus funnel, we changed our
            filter position to top as an experiment one year back. What we
            understood from the data is -
          </p>
          <br />
          <br />
          <p className="bodyTextTrainLeftBottomSections">
            1. <span className="spanBold">Filter usage remained the same</span>{" "}
            in the bus when it was moved from bottom to top.
            <br />
            <br />
            2.{" "}
            <span className="spanBold">
              Usage of filters and sorters were not much
            </span>{" "}
            and restricted to power users.
            <br />
            <br />
            3. Users have <span className="spanBold">used filters</span> that
            are hidden one level below in the filter menu and{" "}
            <span className="spanBold">
              not necessarily from the quick filters list.
            </span>
          </p>
          <img src={Filters} alt="Flow" className="imageTrainCardsFlow " />
          <p className="bodyTextTrain">
            So now we wanted to move back the filter to bottom so that space can
            be utilised properly and is consistent with other lines of business
            in the app. This also helped us in reducing the footprint of the
            filter and sorter section, which meant that more real-estate is now
            present for the listing cards to occupy.
            <br />
            <br />
            Shown below were the 2 variations selected for final user testing
            with a date changer in place and also filter and sorter section
            moving at the bottom of the screen.
          </p>
          <img src={ListingA} alt="Flow" className="imageTrainCardsFlow " />
          <br />
          <img src={ListingB} alt="Flow" className="imageTrainCardsFlow " />
          <p className="bodyTextTrain">
            Next on our agenda is the Traveler Details page. Below are the
            sections of the old traveler details page.
          </p>
          <img src={OldTravel} alt="Flow" className="imageTrainCardsFlow " />
          <p className="bodyTextTrain">
            The first thing we wanted to do was modify the train information
            section and make it similar to the listing page cards so that the
            user stays in context from the listing page. Also, at the top, we
            show train stops information. If the train runs from different stops
            we used to show it at the top. We decided to move it below Boarding
            stations which would be more contextual. We also introduced a
            section at the top which notifies the user if the train has dynamic
            pricing (price changes depending upon demand and time of the day
            when the train is booked). Overall, we reduced the height of the
            section from the previous version.
          </p>
          <img
            src={newTravelInfo}
            alt="Flow"
            className="imageTrainCardsFlow "
          />
          <p className="bodyTextTrain">
            Next comes the “Select Traveler” section. We observed that each
            traveler information can be done in 2 lines or at max 3 lines.
            Gender and age were moved alongside the name to have a more compact
            traveler info section. This again helped in reducing the overall
            height of the section.
            <br />
            <br />
            We also brought the “IRCTC Account Details” section above the
            “Select Traveler” section. Since this was a mandatory step from
            IRCTC and came before the traveler details page, so we put it before
            the “Select Traveler” section. Also, we removed the password
            requested section from there as the password is asked at the end of
            the payment of the ticket. So there was no need of asking a password
            here, thus reducing the overall height of the card.
            <br />
            <br />
            “Contact Details” section was moved to the bottom of the page before
            a newly introduced wallet and discount section.
          </p>
          <img src={SelectTravel} alt="Flow" className="imageTrainCardsFlow " />
          <p className="bodyTextTrain">
            Next, we have the “More Preferences” section. Berth preference
            choices were one step hidden inside a dropdown, which was most used,
            insurance & terms and conditions were clubbed together in one single
            line. All of these which are actually 3 different sections were put
            in one section. What if users don’t want to take insurance but agree
            to the terms and conditions. We modified this sections by clearly
            separating them and bring out the most used preferences out of the
            dropdown.
          </p>
          <img
            src={AdditionalInfo}
            alt="Flow"
            className="imageTrainCardsFlow "
          />
          <p className="bodyTextTrain">
            We introduced an “Offers & Discount” Section as we have in other
            lines of business. All wallet rewards are pre-applied and any
            applicable train coupons are shown so that users won’t go out of the
            funnel to search for train coupons.
          </p>
          <img src={Discount} alt="Flow" className="imageTrainCardsFlow " />
          <p className="bodyTextTrain">
            Finally, the “Terms & Conditions” section was made a separate
            section and put just before the “Pay” CTA at the end.
          </p>
          <img src={Agree} alt="Flow" className="imageTrainCardsFlow " />
          <p className="bodyTextTrain">
            Shown below is the final design of the “Traveler Details” page. Now
            we have the designs for the listing page and traveler details page
            and are ready for the user testing sessions.
          </p>
          <img
            src={NewTravelerPage}
            alt="Flow"
            className="imageTrainCardsFlow "
          />
        </div>
      </div>

      <div className="sectionWrapperCenter">
        <p className="headingText">
          Modifications After
          <br />
          User Testing
        </p>
        <p className="extraMargin bodyTextTrain">
          The user tests went fine without many red flags. We now know what to
          modify. The Lisitng page needed a few changes and then the traveler
          details page went fine. The class of ticket types were changed to
          cards for better viusal perception.{" "}
          <span className="spanBold">
            We also decided to bring back “Nearby Dates” and add a new feature
            “View confirmed Option”.
          </span>
          <br />
          <br />
          This new feature would show the user a confirmed train option which
          might be a little bit more expensive as the user may be charged for a
          boarding few stations earlier then the intended station or getting
          down few stations later.
        </p>
        <img src={newListingCard} alt="Flow" className="imageTrains " />
      </div>

      <div className="lightBg">
        <div className="sectionWrapperCenter ">
          <p className="headingText">
            Results After
            <br />
            Redesign Release
          </p>
          <p className="extraMargin bodyTextTrain">
            After release, the conversion from the listing page to the traveler
            details page and the traveler details to the review page increased
            significantly.
          </p>
          <br />
          <br />
          <p className="bodyTextTrainLeftBottomSectionsLast">
            1. The percentage of users moving from{" "}
            <span className="spanBold">
              listing page to the traveler details increased by almost 30%
            </span>{" "}
            when compared to the numbers before the redesign.
            <br />
            <br />
            2. The percentage of users moving from{" "}
            <span className="spanBold">
              traveler details to the review page went up by almost 18%.
            </span>
          </p>
        </div>
      </div>
      <div className="sectionWrapperCenterLast">
        <p className="headingText">What’s Next?</p>
        <p className="extraMargin bodyTextTrain">
          After the release of this redesign, we saw a significant improvement
          in our page to page conversion numbers. We are constantly monitoring
          the user interaction and pattern in the app and also reviewing calls
          and chats of our users to the customer support team to understand what
          features can we add to improve the experience.
        </p>
      </div>
    </>
  );
}

export default TrainRedesign;
