import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import Jan from "../ExperimentPages/MumbaiWeatherAssets/jan.png";
import Feb from "../ExperimentPages/MumbaiWeatherAssets/feb.png";
import Mar from "../ExperimentPages/MumbaiWeatherAssets/mar.png";
import Apr from "../ExperimentPages/MumbaiWeatherAssets/apr.png";
import May from "../ExperimentPages/MumbaiWeatherAssets/may.png";
import Jun from "../ExperimentPages/MumbaiWeatherAssets/jun.png";
import Jul from "../ExperimentPages/MumbaiWeatherAssets/jul.png";
import Aug from "../ExperimentPages/MumbaiWeatherAssets/aug.png";
import Sep from "../ExperimentPages/MumbaiWeatherAssets/sep.png";
import Oct from "../ExperimentPages/MumbaiWeatherAssets/oct.png";
import Nov from "../ExperimentPages/MumbaiWeatherAssets/nov.png";
import Dec from "../ExperimentPages/MumbaiWeatherAssets/dec.png";
import AllMonths1 from "../ExperimentPages/MumbaiWeatherAssets/allMonths1.png";
import AllMonths2 from "../ExperimentPages/MumbaiWeatherAssets/allMonths2.png";

function MumbaiWeather() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>50 Years of Mumbai Weather (1951-2000)</h1>
        <p className="description">
          This is one of the projects done during my masters program at IDC, IIT
          Bombay. 50 years of weather data recorded at Santa Cruz, Mumbai was
          used to create this visualization. This project was done under the
          curriculum of Data Art. The visualization is done using Processing.
          The project was done under the guidance of Prof. Venkatesh
          Rajamanickam.
        </p>
        <p>
          Please check out the code from github{" "}
          <a
            href="https://github.com/baisampayans/50-years-of-Mumbai-weather"
            className="linkExperiment"
          >
            here.
          </a>
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={Jan} alt="" className="projectImages" />
        <img src={Feb} alt="" className="projectImages" />
        <img src={Mar} alt="" className="projectImages" />
        <img src={Apr} alt="" className="projectImages" />
        <img src={May} alt="" className="projectImages" />
        <img src={Jun} alt="" className="projectImages" />
        <img src={Jul} alt="" className="projectImages" />
        <img src={Aug} alt="" className="projectImages" />
        <img src={Sep} alt="" className="projectImages" />
        <img src={Oct} alt="" className="projectImages" />
        <img src={Nov} alt="" className="projectImages" />
        <img src={Dec} alt="" className="projectImages" />
        <img src={AllMonths1} alt="" className="projectImages" />
        <img src={AllMonths2} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default MumbaiWeather;
