import React from "react";
import { motion } from "framer-motion";
import Profile from "./aboutMe.png";
import "./aboutMe.css";

function About() {
  return (
    <motion.div className="resumeWrapper">
      <div className="resumeTop">
        <div className="profileWrapper">
          <img src={Profile} alt="" className="profile" />
        </div>
        <div className="name">
          <h1>Baisampayan Saha</h1>
          <p style={{ margin: "0", color: "rgba(255, 255, 255, 0.5)" }}>
            Product Designer,
          </p>
          <p style={{ marginTop: "5px", color: "rgba(255, 255, 255, 0.5)" }}>
            Bengaluru, India
          </p>
        </div>
      </div>
      <div className="resumeBottom">
        <div className="jobExperience">
          <p className="sectionTitle">Experience</p>
          <p className="jobIntro">
            I am a Senior Design Manager leading the MWT Viva Insights team{" "}
            <span style={{ fontWeight: "700", color: "white" }}>
              (Personal & Workplace Insights)
            </span>{" "}
            at{" "}
            <span style={{ fontWeight: "700", color: "white" }}>
              Microsoft.
            </span>{" "}
            Below, I have mentioned a few key projects & responsibilities for
            each role I have worked on at different companies -
          </p>
          <div>
            <p className="jobTitle">
              Microsoft,{" "}
              <span className="jobSubtitle">Senior Design Manager</span>
            </p>
            <p className="jobDesc">Aug, 2021 - Present</p>

            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">MWT Insights</span> I lead a small
              team of 4 designer on two products - Personal & Manager Insights
              and Workplace Insights. One of my responsibilities is to
              collaborate with teams at Redmond and other geographies and help
              envision a better product in collaboration with the design,
              product and engineering team located in different geographies.{" "}
            </p>
          </div>
          <div>
            <p className="jobTitle">
              Microsoft, <span className="jobSubtitle">Senior Designer</span>
            </p>
            <p className="jobDesc">Jan, 2021 - July</p>

            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">MWT Workplace Insights</span> I am
              part of the workplace insights team and collaborate with a a bunch
              of designers from India and Redmond, USA to envision the future of
              personal and workplace analytics. .{" "}
            </p>
          </div>
          <div>
            <p className="jobTitle">
              MakeMyTrip, <span className="jobSubtitle">Manager - UX</span>
            </p>
            <p className="jobDesc">Mar, 2019 - Jan, 2021</p>

            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">Grounds Team UX Manager:</span> I
              led a team of 3 designers. We were responsible for the designs of
              Bus, Cabs, Trains, Metro and FleetSimply charter at MakeMyTrip and
              Bus at GoIbibo. I mainly worked with product managers and
              developers to plan and strategize improvement projects and
              quarterly goals for each line of business and chalk out sprint
              plans accordingly.{" "}
            </p>
            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">Plan user research studies:</span>{" "}
              Collaborated with design researchers to plan user research
              projects, create protocols for user studies, etc.
            </p>
            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">
                Mentor/ Review projects of direct reports:
              </span>{" "}
              Mentored, assigned and reviewed projects of direct reports for
              smooth and efficient working of the team and empower them to
              achieve more.
            </p>
          </div>
          <div>
            <p className="jobTitle" id="extraSpace2">
              MakeMyTrip, <span className="jobSubtitle">Lead Design - UX</span>
            </p>
            <p className="jobDesc">Nov, 2016 - Mar, 2019</p>

            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">Hotels UX Lead</span> I was
              responsible for the design requirements of hotels line of
              business.
            </p>
            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">
                MakeMyTrip Progressive Web App (PWA):
              </span>{" "}
              I led the redesign of PWA flights funnel.
            </p>
            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">MakeMyTrip Lite App:</span> I led
              the team on the design of an experimental sub 1MB app targeted for
              the next 100 million internet users of India.
            </p>
          </div>
          <div>
            <p className="jobTitle" id="extraSpace2">
              Playment (YC 2017 & Google Launchpad 2017),{" "}
              <span className="jobSubtitle">Lead Design </span>
            </p>
            <p className="jobDesc">May, 2016 - Nov, 2016</p>

            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">Redesign of App & Website:</span>{" "}
              The app & website were redesigned to make it user-friendly &
              functional keeping both user & business in focus
            </p>
            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">Internal Tools:</span> Internal
              tools were designed to increase the productivity of the internal
              employees
            </p>
          </div>
          <div>
            <p className="jobTitle" id="extraSpace2">
              Flipkart, <span className="jobSubtitle">UI/UX Designer</span>
            </p>
            <p className="jobDesc">July, 2015 - April, 2016</p>

            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">Flipkart Lite:</span> A progressive
              web app designed for the masses. It was done in collaboration with
              Google
            </p>
            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro"> Flipkart Money:</span> I designed
              a virtual wallet for fast and easy transaction for making payment
              a delightful experience
            </p>
            <p className="jobDesc" id="extraSpace">
              <span className="projectIntro">
                {" "}
                Flipkart Cart, Check out and Post Purchase revamp:
              </span>{" "}
              This revamp reduced the number of drop offs during this check-out
              flow, reduction in calls to customer care & make post purchase
              support hassle free
            </p>
          </div>
          <div>
            <p className="jobTitle" id="extraSpace2">
              TU Darmstadt, <span className="jobSubtitle">Design Intern</span>
            </p>
            <p className="jobDesc">May, 2014 - July, 2014</p>

            <p className="jobDesc" id="extraSpace">
              Design dashboards for a B+ segment car(Klienwagen in German) for
              Indian and German audiences
            </p>
            <p className="jobDesc">Please check out the project here</p>
            <p>
              <a href="https://bit.ly/2LAC6uq" className="links">
                {" "}
                Link 1{" "}
              </a>
              <a href="https://bit.ly/2XjPzxI" className="links" id="linkSpace">
                Link 2
              </a>
            </p>
          </div>
          <div>
            <p className="jobTitle" id="extraSpace2">
              Sterlite Industries (I) ltd,{" "}
              <span className="jobSubtitle">Mechanical Engineer</span>
            </p>
            <p className="jobDesc">November, 2009 - May, 2013</p>

            <p className="jobDesc" id="extraSpace">
              I led a team of 2 engineers to maintain machines in a refinery.
              Schedule maintenance, maintain spares of machines and negotiate
              with third party vendors for regular maintenance and procurement
              of spare parts.
            </p>
          </div>
        </div>

        <div className="skills">
          <p className="sectionTitle">Skills</p>
          <div id="extraSpace2">
            <p className="jobDesc">
              Industrial Design, Interaction Design, User Research, Information
              Architecture, Usability Testing, Affinity mapping, Prototyping
            </p>
            <p className="jobDesc">Sketch, Zeplin, Framer, Figma</p>
            <p className="jobDesc">
              HTML5, CSS3, Basics of SwiftUI/ Xcode, React
            </p>
          </div>
        </div>

        <div className="education">
          <p className="sectionTitle">Education</p>
          <div>
            <p className="jobTitle">Master of Design, Industrial Design</p>
            <p className="jobDesc">
              Industrial Design Centre, IIT Bombay, India
            </p>
            <p className="jobDesc">2013-15</p>
          </div>
          <div>
            <p className="jobTitle">
              Bachelor of Technology, Mechanical Engineering
            </p>
            <p className="jobDesc">
              Mechanical Engineering Department, North Eastern Regional
              Institute of Science & Technology, India
            </p>
            <p className="jobDesc">2005-09</p>
          </div>
        </div>

        <div className="volunteer">
          <p className="sectionTitle">Volunteer</p>
          <div>
            <p className="jobTitle" id="extraSpace2">
              DesignUp Conference{" "}
              <span className="jobSubtitle">Core Team Member</span>
            </p>
            <p className="jobDesc">2016 - Present</p>
            <p className="jobDesc">
              India’s go-to-conference for Design in Tech -{" "}
              <a href="https://designup.io" className="links">
                https://designup.io
              </a>
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default About;
