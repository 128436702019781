import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import Kick from "../../AllExperiments/assets/message_animation.gif";

function Message() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Send Us a Message</h1>
        <p className="description">
          This was an interaction done for the Contact Us form of Playment
          website. The idea was to have a delightful experience of a mundane
          thing as sending a message.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img
          src={Kick}
          alt=""
          className="projectImages"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
        />
      </motion.div>
    </motion.div>
  );
}

export default Message;
