import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import BraunHld from "../../AllExperiments/assets/hld_4.png";

function Hld() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Braun HLD</h1>
        <p className="description">
          This one is a part of a series of illustrations to show products
          designed by Dieter Rams. HLD 4 was a hair dryer designed by Dieter
          Rams. It was designed in 1970.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={BraunHld} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default Hld;
