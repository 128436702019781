import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import ShantiStupa from "../../AllExperiments/assets/travel.gif";

function Travel() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Travel App Concept</h1>
        <p className="description">
          This was a quick prototype done during a design sprint to redesign
          MakeMyTrip app. This is one of the many prototypes that we had done
          during the sprint. This was not one of selected designs. The idea
          behind the spirnt was to simplify the whole funnel and create a more
          personalised experience for the user.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={ShantiStupa} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default Travel;
