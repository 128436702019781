import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import ShantiStupa from "../../AllExperiments/assets/shanti_stupa_leh.png";

function Shanti() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Shanti Stupa</h1>
        <p className="description">
          This is an illustration of Shanti Stupa monastery in Leh, imagined
          during a cold snowy night. The illustration was insprired by the
          visual style of a mobile game app called "Alto's Adventure"
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={ShantiStupa} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default Shanti;
