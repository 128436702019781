import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import BraunTp1 from "../../AllExperiments/assets/braun_tp1.png";

function Tp1() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Braun TP1</h1>
        <p className="description">
          This is one a part of a series of illustrations to show products
          designed by Dieter Rams. TP1 was a portable phone radio designed by
          Dieter Rams. It was designed in 1959.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={BraunTp1} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default Tp1;
