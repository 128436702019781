import React from "react";
import { motion } from "framer-motion";
import "./experimentPages.css";
import Blog from "./PlaymentWebsite/Blog.png";
import Career from "./PlaymentWebsite/CAREER.png";
import Error from "./PlaymentWebsite/error.png";
import Home from "./PlaymentWebsite/home.png";
import Player from "./PlaymentWebsite/player.png";
import Team from "./PlaymentWebsite/TEAM.png";

function PlaymentSite() {
  return (
    <motion.div className="containerWrapper">
      <motion.div>
        <h1>Playment Website Redesign</h1>
        <p className="description">
          Playment just got its seed funding and was getting ready for more
          business and apply for Google Lauchpad and Y-Combinator by the end of
          2016. The website need a redesign.
        </p>
        <p className="description">
          Please check below some of the screenshots of the redesigned website.
        </p>
      </motion.div>
      <motion.div className="imageContainer">
        <img src={Home} alt="" className="projectImages" />
      </motion.div>
      <motion.div className="imageContainer">
        <img src={Player} alt="" className="projectImages" />
      </motion.div>
      <motion.div className="imageContainer">
        <img src={Team} alt="" className="projectImages" />
      </motion.div>
      <motion.div className="imageContainer">
        <img src={Career} alt="" className="projectImages" />
      </motion.div>
      <motion.div className="imageContainer">
        <img src={Blog} alt="" className="projectImages" />
      </motion.div>
      <motion.div className="imageContainer">
        <img src={Error} alt="" className="projectImages" />
      </motion.div>
    </motion.div>
  );
}

export default PlaymentSite;
