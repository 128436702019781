import React from "react";
import { motion } from "framer-motion";
import * as sectionRefHolder from "../../sectionRefHolders";
import "./AllExperiments.css";
import Braun from "./assets/braun_tp1.png";
import Home from "./assets/home.png";
import Calendar from "./assets/calendar.gif";
import GoodBye from "./assets/good_bye_2016.png";
import Hld from "./assets/hld_4.png";
import Kickstarter from "./assets/kickstarter.gif";
import Message from "./assets/message_animation.gif";
import Mumbai from "./assets/Mumbai_weather.gif";
import Shanti from "./assets/shanti_stupa_leh.png";
import Travel from "./assets/travel.gif";
import CirclePacking from "./assets/circlePacking.png";
import Voice from "./assets/voiceDesign.png";
import DesignUp from "./assets/DesignUp.png";
import { NavLink } from "react-router-dom";

function AllExperiments() {
  return (
    <motion.div
      ref={(elRef) => {
        sectionRefHolder.setRef(sectionRefHolder.SECTIONS.EXPERIMENTS, elRef);
      }}
      className="experimentsWrapper"
    >
      <motion.div className="titleGradient">
        <motion.div className="gradient"></motion.div>
        <motion.div className="workTitle">Experiments</motion.div>
      </motion.div>
      <motion.div className="allExperiments">
        <NavLink to="/experiments/blender" className="navlinkStyle">
          <div className="card">
            <img
              src={Home}
              alt="Blender Experiments"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">Blender Experiments</h1>
            <p className="descriptionText">
              Exploring Blender as an open source 3D content creation tool.
            </p>
          </div>
        </NavLink>
        <a
          href="https://medium.com/designers-of-mmt/spilling-the-beans-on-designup-2019-with-baisam-3a3a3bc9adfc"
          className="navlinkStyle"
        >
          <div className="card">
            <img
              src={DesignUp}
              alt="Mumbai Weather"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">
              Spilling-the-beans on ‘DesignUp 2019’
            </h1>
            <p className="descriptionText">
              Excerpts of my interview by Vashima Jain about organising
              DesignUp.
            </p>
          </div>
        </a>
        <a
          href="https://medium.com/designers-of-mmt/designing-for-voice-part-i-e1a4dcc8d937"
          className="navlinkStyle"
        >
          <div className="card">
            <img src={Voice} alt="Mumbai Weather" className="experimentImage" />
            <h1 className="descriptionTitle">Designing for voice</h1>
            <p className="descriptionText">
              Compiled notes from Bruce Balentine's workshop on "Designing for
              voice"
            </p>
          </div>
        </a>
        <NavLink to="/experiments/mumbaiWeather" className="navlinkStyle">
          <div className="card">
            <img
              src={Mumbai}
              alt="Mumbai Weather"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">50 Years Of Mumbai Weather</h1>
            <p className="descriptionText">
              Weather data of Mumbai from 1950-2000 visualized using Processing
              app.
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/p5" className="navlinkStyle">
          <div className="card">
            <img
              src={CirclePacking}
              alt="Circle Packing"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">P5.js + React.js explorations</h1>
            <p className="descriptionText">
              Exploring P5.js and React.js while following Daniel Shiffman's
              tutorials for learning Processing
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/tp1" className="navlinkStyle">
          <div className="card">
            <img src={Braun} alt="Braun TP1" className="experimentImage" />
            <h1 className="descriptionTitle">Braun TP1</h1>
            <p className="descriptionText">
              Part of a series of illustration of Braun products
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/calendar" className="navlinkStyle">
          <div className="card">
            <img src={Calendar} alt="Calendar" className="experimentImage" />
            <h1 className="descriptionTitle">Calendar</h1>
            <p className="descriptionText">
              Alternate interaction for calendar feature of MMT app
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/powaiLake" className="navlinkStyle">
          <div className="card">
            <img src={GoodBye} alt="Sunset" className="experimentImage" />
            <h1 className="descriptionTitle">Powai Lake Sunset</h1>
            <p className="descriptionText">
              Illustration of sunset at viewed from Powai lake, Mumbai
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/hld" className="navlinkStyle">
          <div className="card">
            <img src={Hld} alt="Braun HLD" className="experimentImage" />
            <h1 className="descriptionTitle">Braun Hld</h1>
            <p className="descriptionText">
              Part of a series of illustration of Braun products
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/kickstarter" className="navlinkStyle">
          <div className="card">
            <img
              src={Kickstarter}
              alt="Kickstarter logo"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">Kickstarter Logo Animation</h1>
            <p className="descriptionText">
              Kickstarter logo is animation using Framer Studio
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/message" className="navlinkStyle">
          <div className="card">
            <img src={Message} alt="Message" className="experimentImage" />
            <h1 className="descriptionTitle">Send Us a Message</h1>
            <p className="descriptionText">
              Contact form done for Playment website redesign - 2016
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/shanti" className="navlinkStyle">
          <div className="card">
            <img src={Shanti} alt="Shanti Stupa" className="experimentImage" />
            <h1 className="descriptionTitle">Shanti Stupa</h1>
            <p className="descriptionText">Illustration of Shanti Stupa, Leh</p>
          </div>
        </NavLink>
        <NavLink to="/experiments/travel" className="navlinkStyle">
          <div className="card">
            <img src={Travel} alt="Travel App" className="experimentImage" />
            <h1 className="descriptionTitle">Travel App Concept</h1>
            <p className="descriptionText">
              This was an exercise done to revisualize MMT. It was one of the
              concept that didn't make the cut.
            </p>
          </div>
        </NavLink>
      </motion.div>
      <motion.div>
        <NavLink to="/experiments" style={{ textDecoration: "none" }}>
          <p className="SeeMoreExp">View More Projects</p>
        </NavLink>
      </motion.div>
    </motion.div>
  );
}

export default AllExperiments;
