import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./fonts/Montserrat-Black.ttf";
import "./fonts/Montserrat-Light.ttf";
import "./fonts/Montserrat-Regular.ttf";
import Home from "./Home";

ReactDOM.render(<Home/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
