import React from "react";
import { motion } from "framer-motion";
import "./Footer.css";

function Footer() {
  return (
    <motion.div className="footer">
      <motion.div className="footerWrapper">
        <motion.div className="container1">
          <p>
            Follow me on{" "}
            <a href="https://twitter.com/baisampayans" className="social">
              Twitter
            </a>
            ,{" "}
            <a
              href="https://www.linkedin.com/in/baisampayans/"
              className="social"
            >
              LinkedIn
            </a>
            ,{" "}
            <a href="https://dribbble.com/baisampayans" className="social">
              Dribbble
            </a>{" "}
            &{" "}
            <a href="https://medium.com/@baisampayans" className="social">
              Medium
            </a>{" "}
            for more updates
          </p>
          <p className="contactMe">
            Or Send me a message{" "}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeiDcRonb38e6a78XYhYcVbZIjUcqfFbNet9T3LDnPKy19VnQ/viewform?vc=0&c=0&w=1"
              className="social"
            >
              here
            </a>
          </p>
        </motion.div>
        <motion.div className="container2">
          <p>
            I created this site using React. I would like to specially thank
            <a href="https://twitter.com/rajeshbatth" className="thanks">
              {" "}
              Rajesh
            </a>{" "}
            in helping me out whenever I got stuck with trivial css or
            javascript issues,{" "}
            <a
              href="https://www.linkedin.com/in/hengul-d-152b66143/"
              className="thanks"
            >
              Hengul
            </a>{" "}
            for helping out with the introduction copy, my{" "}
            <span className="help">teammates</span> and{" "}
            <span className="help">colleagues</span> for putting up with my
            crazy ideas, trusting me and being there with me till the end of
            each project.
          </p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default Footer;
