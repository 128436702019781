import React from "react";
import { motion } from "framer-motion";
import Mumbai from "../AllExperiments/assets/Mumbai_weather.gif";
import CirclePacking from "../AllExperiments/assets/circlePacking.png";
import Braun from "../AllExperiments/assets/braun_tp1.png";
import Home from "../AllExperiments/assets/home.png";
import Calendar from "../AllExperiments/assets/calendar.gif";
import GoodBye from "../AllExperiments/assets/good_bye_2016.png";
import Hld from "../AllExperiments/assets/hld_4.png";
import Kickstarter from "../AllExperiments/assets/kickstarter.gif";
import Message from "../AllExperiments/assets/message_animation.gif";
import Shanti from "../AllExperiments/assets/shanti_stupa_leh.png";
import WebsiteIllustration from "../AllExperiments/assets/website_illustrations.png";
import WebSiteHome from "../AllExperiments/assets/website_home.png";
import Welder from "../AllExperiments/assets/welder_nation.png";
import Onboarding from "../AllExperiments/assets/onboarding.png";
import Travel from "../AllExperiments/assets/travel.gif";
import "../AllExperiments/AllExperiments.css";
import Voice from "../AllExperiments/assets/voiceDesign.png";
import DesignUp from "../AllExperiments/assets/DesignUp.png";
import { NavLink } from "react-router-dom";

function Experiments() {
  return (
    <motion.div className="wrapper">
      <motion.div className="allExperiments">
        <NavLink to="/experiments/blender" className="navlinkStyle">
          <div className="card">
            <img
              src={Home}
              alt="Blender Experiments"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">Blender Experiments</h1>
            <p className="descriptionText">
              Exploring Blender as an open source 3D content creation tool.
            </p>
          </div>
        </NavLink>
        <a
          href="https://medium.com/designers-of-mmt/spilling-the-beans-on-designup-2019-with-baisam-3a3a3bc9adfc"
          className="navlinkStyle"
        >
          <div className="card">
            <img
              src={DesignUp}
              alt="Mumbai Weather"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">
              Spilling-the-beans on ‘DesignUp 2019’
            </h1>
            <p className="descriptionText">
              Excerpts of my interview by Vashima Jain about organising
              DesignUp.
            </p>
          </div>
        </a>
        <a
          href="https://medium.com/designers-of-mmt/designing-for-voice-part-i-e1a4dcc8d937"
          className="navlinkStyle"
        >
          <div className="card">
            <img src={Voice} alt="Mumbai Weather" className="experimentImage" />
            <h1 className="descriptionTitle">Designing for voice</h1>
            <p className="descriptionText">
              Compiled notes from Bruce Balentine's workshop on "Designing for
              voice"
            </p>
          </div>
        </a>
        <NavLink to="/experiments/mumbaiWeather" className="navlinkStyle">
          <div className="card">
            <img
              src={Mumbai}
              alt="Mumbai Weather"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">50 Years Of Mumbai Wearther</h1>
            <p className="descriptionText">
              Weather data of Mumbai from 1950-2000 visualized using Processing
              app.
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/p5" className="navlinkStyle">
          <div className="card">
            <img
              src={CirclePacking}
              alt="Circle Packing"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">P5.js + React.js explorations</h1>
            <p className="descriptionText">
              Exploring P5.js and React.js while following Daniel Shiffman's
              tutorials for learning Processing
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/tp1" className="navlinkStyle">
          <div className="card">
            <img src={Braun} alt="Braun TP1" className="experimentImage" />
            <h1 className="descriptionTitle">Braun TP1</h1>
            <p className="descriptionText">
              Part of a series of illustration of Braun products
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/calendar" className="navlinkStyle">
          <div className="card">
            <img src={Calendar} alt="Calendar" className="experimentImage" />
            <h1 className="descriptionTitle">Calendar</h1>
            <p className="descriptionText">
              Alternate interaction for calendar feature of MMT app
            </p>
          </div>
        </NavLink>

        <NavLink to="/experiments/powaiLake" className="navlinkStyle">
          <div className="card">
            <img src={GoodBye} alt="Sunset" className="experimentImage" />
            <h1 className="descriptionTitle">Powai Lake Sunset</h1>
            <p className="descriptionText">
              Illustration of sunset at viewed from Powai lake, Mumbai
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/hld" className="navlinkStyle">
          <div className="card">
            <img src={Hld} alt="Braun TP1" className="experimentImage" />
            <h1 className="descriptionTitle">Braun Hld</h1>
            <p className="descriptionText">
              Part of a series of illustration of Braun products
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/kickstarter" className="navlinkStyle">
          <div className="card">
            <img
              src={Kickstarter}
              alt="Kickstarter logo"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">Kickstarter Logo Animation</h1>
            <p className="descriptionText">
              Kickstarter logo is animation using Framer Studio
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/message" className="navlinkStyle">
          <div className="card">
            <img src={Message} alt="Message" className="experimentImage" />
            <h1 className="descriptionTitle">Send Us a Message</h1>
            <p className="descriptionText">
              Contact form done for Playment website redesign - 2016
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/shanti" className="navlinkStyle">
          <div className="card">
            <img src={Shanti} alt="Shanti Stupa" className="experimentImage" />
            <h1 className="descriptionTitle">Shanti Stupa</h1>
            <p className="descriptionText">Illustration of Shanti Stupa, Leh</p>
          </div>
        </NavLink>
        <NavLink to="/experiments/travel" className="navlinkStyle">
          <div className="card">
            <img src={Travel} alt="Travel App" className="experimentImage" />
            <h1 className="descriptionTitle">Travel App Concept</h1>
            <p className="descriptionText">
              This was an exercise done to revisualize MMT. It was one of the
              concept that was rejected.
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/icons" className="navlinkStyle">
          <div className="card">
            <img
              src={WebsiteIllustration}
              alt="Braun TP1"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">Icon Illustrations</h1>
            <p className="descriptionText">
              A set of icons done during the redesign of the website of Playment
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/welder" className="navlinkStyle">
          <div className="card">
            <img src={Welder} alt="Braun TP1" className="experimentImage" />
            <h1 className="descriptionTitle">Welder Nation Illustration</h1>
            <p className="descriptionText">
              This was one of the freelance work that I had done for Welder
              Nation
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/playmentWebsite" className="navlinkStyle">
          <div className="card">
            <img
              src={WebSiteHome}
              alt="Braun TP1"
              className="experimentImage"
            />
            <h1 className="descriptionTitle">Playment Website Redesign</h1>
            <p className="descriptionText">
              Screenshots of the old Playment site that I redesigned in the year
              2016
            </p>
          </div>
        </NavLink>
        <NavLink to="/experiments/onboarding" className="navlinkStyle">
          <div className="card">
            <img src={Onboarding} alt="Braun TP1" className="experimentImage" />
            <h1 className="descriptionTitle">App Onboarding</h1>
            <p className="descriptionText">
              Side project to hone illustration skill
            </p>
          </div>
        </NavLink>
      </motion.div>
    </motion.div>
  );
}

export default Experiments;
